import logo from './indorange.png';
import './App.css';
import { Fragment } from 'react';
import { AiFillPhone, AiOutlineMail } from 'react-icons/ai';

function App() {
  return (
    <Fragment>
      <header class="fixed top-0 w-full clearNav z-50 bg-white">
        <div class="max-w-7xl mx-auto flex flex-wrap  flex-col md:flex-row">
          <div className="flex flex-row items-center justify-between  md:p-1">
            <img
              src={logo}
              alt={"indorange Logo"}
              className="dark:invert h-24 w-auto"
              // width={300}
              // height={130}
              priority
            />
          </div>
          {/* <div className={"md:flex flex-grow items-center flex"} >
            <div class="md:ml-auto md:mr-auto font-4 pt-1 md:pl-14 pl-1 flex flex-wrap items-center md:text-base text-1xl md:justify-center justify-items-start">
              <p class="mr-11 pr-2 cursor-pointer text-gray-700 hover:text-gray-500 font-semibold tr04">
                Features
              </p>

              <p class="mr-12 md:ml-11 ml-0 cursor-pointer text-gray-700 hover:text-gray-500 font-semibold tr04">
                Pricing
              </p>
              <p class="mr-5 cursor-pointer text-gray-700 hover:text-gray-500 font-semibold tr04">
                Careers
              </p>
            </div>
          </div> */}
        </div>
      </header>
      <section class="text-gray-600 body-font">
        <div class="max-w-5xl pt-52 pb-20 mx-auto">
          {/* <h1 class="text-80 text-center font-4 lh-6 ld-04 font-bold text-orange-500 mb-6 text-5xl">
            Indorange
          </h1> */}
          <h1 class="text-3xl lg:text-6xl md:text-5xl font-4 font-semibold lh-6 ld-04  text-orange-500 text-center">
            IndOrange Agritech & Farms

          </h1>

        </div>
        <div className="container flex flex-col items-center justify-center mx-auto">
          <img
            src={logo}
            alt={"indorange Logo"}
            className="dark:invert"
            width={"auto"}
            height={20}
          />
        </div>
        <h2 className=" mb-1 text-2xl font-semibold tracking-tighter text-center text-gray-400 lg:text-6xl md:text-5xl">
          We've got what you need!
          <br />
          your Problem - Our Solution
        </h2>
        <br></br>

        <h3 className=" mb-1 mt-20 text-xl font-semibold tracking-tighter text-center text-gray-700 lg:text-4xl md:text-3xl mb-5">
          About IndOrange
        </h3>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          Pests have always challenged human existence by harming its agricultural production and food availability, consequently increasing the cost of food. The modern response to this problem has been the advent of pesticides, that achieved secure dependable food sources, however it is currently accomplished at the expense of human health and long term damage to the environment.
        </p>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          Indorange agritech has developed an innovative and effective, Patented, control technology that without harming human health and the environment , enables users to continuously control pests, currently for up to 1 year, and in the future for up to 3 years.
        </p>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          The Company was founded by Mr. R.K Patel , a fruit fly ecologist who began his work as a fruit grower and indorange regime , which is especially suitable for fruit fly Area-Wide-Control, and can be easily adapted to other pest species.
        </p>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          Indorange technology has been initially applied to fruit flies, which are the most significant group of fruit-infesting about pests throughout the world, and therefore causes the most extensive (from 30 up to 100%) damage to the fruit-growing industry. Current fruit fly control methods rely primarily on toxic insecticide sprays, posing a threat to growers, the general public and the environment. Consequently, current Requirements are stricter and aim for zero fruit-fly infestation and zero chemical residues. Indorange's innovative solution consists of a simple, easy to use 'feeding station' utilizing a unique, innovative (Patented) Slow Liquid Release technology, specific to the target pest, and working continuously for up to a year.
        </p>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          Growers using the Indorange-Regime have experienced a sharp - 98% on average - decrease in fruit fly spraying , about us alongside a simultaneous drop of 93% in infestation, compared to a spraying regime! Note: It is further reported that this decrease is followed by over 60% less spraying for the control of pests, other than fruit flies.
        </p>
        <p className="mx-auto text-lg text-center text-gray-500 font-normal leading-relaxed fs521 lg:w-2/3 mb-4">
          Future developments and innovations, based on unique know how and Patents, will increase the effective period of the product for up to three years, using fewer units per hectare and will enable simultaneous control of several pests.
        </p>

        <section class="relative pb-24">
          <div class="max-w-6xl mx-auto px-4 sm:px-6 text-center">
            <div class="py-24 md:py-36">
              <p class="mb-16 text-6xl font-bold text-gray-400">
                Let's Get In Touch!
              </p>
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
                <div className='text-center items-center '>
                  <AiFillPhone size={50} className='inline-block mb-2' />
                  <p><a href="tel:+919098497559"> +91 90984 97559</a></p>
                </div>
                <div className='text-center items-center'>
                  <AiOutlineMail size={50} className='inline-block mb-2 text-orange-500' />
                  <p><a class="d-block text-orange-500" href="indorangeagritech@gmail.co">indorangeagritech@gmail.com</a></p>
                </div>

              </div>

            </div>
          </div>
        </section>
      </section>

      <footer className="pb-4 text-gray-700">
        <div className="max-w-5xl xl:max-w-5xl mx-auto divide-y divide-gray-900 px-4 sm:px-6 md:px-8">

          <div className="flex flex-col-reverse justify-center pt-5 pb-4 border-t lg:flex-row bg-top border-black">
            <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
              <a
                href="/"
                className="text-md text-gray-700 hover:text-gray-500 transition-colors duration-300 hover:text-deep-purple-accent-400 font-semibold tracking-tight"
              >
                © 2023 Indorange Inc.
              </a>
            </ul>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

export default App;
